import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Seo from "../components/seo";
import { LocaleContext } from "../components/layout";

export default function Chatbot({ data }) {
  const { locale, url } = React.useContext(LocaleContext);
  const page = data.markdownRemark;

  return (
    <>
      <Seo
        url={url}
        lang={locale}
        title={page.frontmatter.title}
        description={page.html}
        bodyClass="page-chatbot"
      />
      <div className="chatbot container">
        <div
          className="chatbot__description"
          dangerouslySetInnerHTML={{ __html: page.html }}
        />
        <div className="chatbot__row row">
          {/* <a
            href="https://wa.me/491602087567"
            target="_blank"
            rel="nofollow noreferrer"
            className="chatbot__link"
          >
            <StaticImage
              src="../images/icon-whatsapp.png"
              alt="Whatsapp"
              className="chatbot__link-img"
            />
            <span className="chatbot__link-title">WhatsApp</span>
          </a>*/}

          <a
            href="https://t.me/offenerprozess_bot"
            target="_blank"
            rel="nofollow noreferrer"
            className="chatbot__link"
          >
            <StaticImage
              src="../images/icon-telegram.png"
              alt="Telegram"
              className="chatbot__link-img"
            />
            <span className="chatbot__link-title">Telegram</span>
          </a>

          <a
            href="http://offener-prozess.de/chatbot/"
            target="_blank"
            rel="nofollow noreferrer"
            className="chatbot__link"
          >
            <StaticImage
              src="../images/icon-browser.png"
              alt="Browser"
              className="chatbot__link-img"
            />
            <span className="chatbot__link-title">Browser</span>
          </a>
        </div>
      </div>
    </>
  );
}

export const query = graphql`
  query($locale: String!) {
    markdownRemark(
      frontmatter: { template: { eq: "chatbot" } }
      fields: { locale: { eq: $locale } }
    ) {
      html
      frontmatter {
        title
      }
    }
  }
`;
